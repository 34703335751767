import { AddressFormFields } from '@/interfaces/interfaces'
import supabase from '@/lib/supabaseClient'
import {
  Banner,
  Category,
  MarketplaceCategoriesWithChilds,
  ItemMarketplace,
  HelpItemWithHelpSubItems,
  Variable,
  ShipmentMethod,
  ItemInput,
  Item,
  OrderSummary,
  PaymentMethod,
  CreditCard,
  UserAddresses,
  Island,
  User,
  Address,
  OrderData,
  Status,
  IgicType,
  MarketPlaceCategoryRelation,
  Marketplace,
  Supplement,
  RoleId,
  BusinessOrderData,
  CompanyOrder
} from '@/types/supabase.types'
import { MarketplaceRelations } from '@/types/supabase.types'
import { AddressEnum } from '@/types/enums'
import { CategoryMarketplaces } from 'types/supabase.types'
import { DEFAULT_SHIPPING_METHOD_ID, LEGAL_ADVISE, PRIVACY_POLICY, COOKIES_POLICY, BUY_CONDITIONS, VAR_LOW_VALUE_LIMIT, VAR_SHIPPING_INSURANCE, IGIC_TYPE_REDUCIDO, IGIC_TYPE_GENERAL, DEFAULT_SHIPMODE_SHIPPING_METHOD_ID, ORDER_STATUS_ID_PENDING_PAYMENT, ORDER_STATUS_ID_ADMITTED, ORDER_STATUS_ID_PENALIZATION, ORDER_STATUS_ID_PREPARED, ORDER_STATUS_ID_PROCESSED, ORDER_STATUS_ID_REQUESTED, ORDER_STATUS_ID_SENT, ORDER_STATUS_ID_SUPPLEMENT, SITES_DOMAINS_EXCLUDES, BLACKLIST_STORES, LOW_IGIC_VALUE, MAX_KG_DEFAULT_SHIPPING_PRICE, CUSTOM_ORDER_MANAGEMENT_PRICE, FREE_SHIPMENT_ECONOMY, FREE_ECONOMY_PRICE_MIN, BUSINESS_STATUS_REQUESTED_ID, BUSINESS_STATUS_CANCELLED_ID, SENT_BUDGE_STATUS_ID } from './constants'
import { igic, igicForItem, priceWithoutIva, savings, shippingInsurance, subtotal, synivaPrice, total } from './orderCalculator'
import { validateNif } from './validations'
import { checkCodeConditions, checkInternalShop, getOriginalUrl, prepareItemUrl, prepareUrl } from './util'
import { definitions } from '@/interfaces/supabase'

export async function getMarketPlaceCategories(): Promise<MarketplaceCategoriesWithChilds[] | null> {
  try {
    let { data: marketplace_categories, error } = await supabase
      .from<Category>('marketplace_categories')
      .select('*')
      .order('weight', { ascending: true })
      .neq('is_in_home', false)
      .eq('deleted', false)

    if (error) {
      throw Error(error.message)
    }

    if (!error && marketplace_categories == null) return null

    let amazonData: any | null = null
    const cat = marketplace_categories?.find(x => x.name.toLowerCase() === 'amazon')
    const categoryId = (cat) ? cat.id : 0
    if (categoryId) {
      const { data: dataRelation, error: errorRelation } = await supabase
        .from<MarketPlaceCategoryRelation>('marketplace_category_relations')
        .select('marketplace_id')
        .eq('marketplace_category_id', categoryId)

      if (dataRelation) {
        const { data: dataMarketplace, error: errorMarketplace } = await supabase
          .from<Marketplace>('marketplaces')
          .select('id, name, url')
          .eq('id', dataRelation[0].marketplace_id)
          .single()

        if (dataMarketplace && dataMarketplace.url && dataMarketplace.url !== null && dataMarketplace.url !== '') {
          amazonData = dataMarketplace
        }
      }
    }

    return marketplace_categories!
      .map((category: any) => {
        if (category.name.toLowerCase() === 'amazon') {
          if (amazonData !== null) {
            category.url = amazonData.url
          }
          const childCategories = marketplace_categories!.filter((child) => child.parent_id === category.id)
          return {
            ...category,
            childCategories,
          }
        }
        if (category.parent_id == null) {
          const childCategories = marketplace_categories!.filter((child) => child.parent_id === category.id)
          return {
            ...category,
            childCategories,
          }
        }
      })
      .filter((category) => category != null) as MarketplaceCategoriesWithChilds[]
  } catch (error) {
    console.error('Error fetching marketplace categories', (error as Error).message)
    return null
  }
}

export async function getMarketplaces() {
  try {
    const response = await fetch('/api/get-marketplaces')
    const { data: logos, error } = await response.json()

    if (error) throw Error(error.message)
    if (!error && !logos) return null

    return logos
  } catch (error) {
    console.error('Error fetching marketplaces', (error as Error).message)
    return null
  }
}

export async function getMarketplacesRelations() {
  try {
    let { data: marketplace_relations, error } = await supabase
      .from<MarketplaceRelations>('marketplace_relations')
      .select('*')

    if (error) throw Error(error.message)
    if (!error && !marketplace_relations) return null

    return marketplace_relations
  } catch (error) {
    console.error('Error fetching marketplaces relations', (error as Error).message)
    return null
  }
}

export async function getCategoryMarketplaces(categoryId: string): Promise<CategoryMarketplaces | null> {
  try {
    const response = await fetch(`/api/get-categories-marketplaces/${categoryId}`)
    const { data: marketplaces, error } = await response.json()

    if (error) throw Error(error.message)
    if (!error && !marketplaces) return null

    return marketplaces
  } catch (error) {
    console.error('Error fetching marketplaces', (error as Error).message)
    return null
  }
}

export async function getBlackList() {
  try {
    const { data } = await supabase.from('variables')
      .select('*')
      .eq('name', BLACKLIST_STORES)
      .single()

    return data.value
  } catch (error) {
    console.log(error)
  }
}


export async function createCustomShop(shopData: any) {
  try {
    // if shopData.site has value, in other case prepara the url to work
    let siteName = (shopData?.custom_order_site) ? shopData.custom_order_site : prepareUrl(shopData.url)

    siteName = prepareItemUrl(siteName)

    if (siteName === '' || siteName === null) siteName = prepareItemUrl(prepareUrl(shopData.url))

    // Check the original url in the marketplaces table
    const { data: customShopData } = await supabase.from('marketplaces')
      .select('*')
      .ilike('name', `%${siteName}%`)
      .eq('deleted', false)

    const { data: internalShopData } = await supabase.from('marketplaces')
      .select('*')
      .ilike('name', `%${prepareItemUrl(prepareUrl(shopData.url))}%`)
      .eq('deleted', false)
      .eq('is_custom_shop', false)


    let internalShop = null
    if (internalShopData) internalShop = checkInternalShop(internalShopData, siteName)

    // if is not register run the rest of the function in other case, nothing
    if (customShopData && customShopData?.length === 0) {
      const oriUrl = getOriginalUrl(shopData.url)

      await supabase.from('marketplaces')
        .insert({
          name: siteName,
          status: false,
          free_shipment_price: 100000,
          shipment_price: 0,
          is_in_home: false,
          logo: null,
          igic_type_id: 2,
          deleted: false,
          weight: 999,
          original_url: oriUrl,
          is_in_app: false,
          extra_info: { comesFromUrl: shopData.url },
          is_custom_shop: true,
          internal_shop_id: internalShop?.shop_id || null
        })
    }

  } catch (error) {
    console.log(error)
  }
}

export async function getBanners(): Promise<Array<Banner[]> | null> {
  let finalBanners = new Array()
  try {
    let { data: banners, error } = await supabase
      .from<Banner>('banners')
      .select('position, banner_url, redirect_url')
      .eq('is_in_home', true)
      .eq('deleted', false)

    if (error) throw Error(error.message)

    if (!error && banners == null) return null

    finalBanners[0] = banners?.filter((b) => b.position == '1')
    finalBanners[1] = banners?.filter((b) => b.position == '2')
    finalBanners[2] = banners?.filter((b) => b.position == '3')
    finalBanners[3] = banners?.filter((b) => b.position == '4')

    return finalBanners

  } catch (error) {
    console.error('Error fetching banners', (error as Error).message)
    return null
  }
}

export async function getMainImages(): Promise<any[] | null> {
  try {
    const ac = new AbortController()
    setTimeout(() => {
      ac.abort()
    }, 15 * 1000)

    let { data, error } = await supabase
      .from('variables')
      .select('value')
      .eq('name', 'MOBILE_CONFIG')
      .abortSignal(ac.signal)

    if (error) throw Error(error.message)

    return data

  } catch (error) {
    console.error('Error fetching main images', (error as Error).message)
    return null
  }
}

export async function getMainTextBannerConfig(): Promise<any[] | null> {
  try {
    let { data, error } = await supabase
      .from('variables')
      .select('value')
      .eq('name', 'BANNER_INFO_CONFIG')

    if (error) throw Error(error.message)

    return data

  } catch (error) {
    console.error('Error fetching main text banner', (error as Error).message)
    return null
  }
}

export async function getHelp(): Promise<HelpItemWithHelpSubItems[] | null> {
  try {
    let { data: helpItems, error } = await supabase
      .from<HelpItemWithHelpSubItems>('cms_categories')
      .select('id, name, cms_config, cms(id, name, content, cms_parent)')
      .order('weigth', { foreignTable: 'cms', ascending: true })
      .eq('deleted', false)
      .eq('is_in_home', true)
      // @ts-ignore
      .eq('cms.deleted', false) // TODO it works fine but it won't deploy because DO typescript type restrictions. remove comment above and fix typescript relation
      // @ts-ignore
      .eq('cms.is_in_home', true) // TODO it works fine but it won't deploy because DO typescript type restrictions. remove comment above and fix typescript relation

    if (error) throw Error(error.message)
    if (!error && helpItems == null) return null

    return helpItems
  } catch (error) {
    console.log('Error fetching banners', (error as Error).message)
    return null
  }
}

export async function getSubItemsHelp(helpId: Number): Promise<any> {
  try {
    let { data: helpItems, error } = await supabase
      .from('cms')
      .select('id, name, content, cms_parent')
      .order('weigth', { ascending: true })
      .eq('deleted', false)
      .eq('is_in_home', true)
      .eq('cms_parent', helpId)

    if (error) throw Error(error.message)
    if (!error && helpItems == null) return null

    let { data: parentItem, error: errorParent } = await supabase
      .from('cms')
      .select('id, name, content')
      .eq('deleted', false)
      .eq('is_in_home', true)
      .eq('id', helpId)
      .single()

    if (errorParent) throw Error(errorParent.message)
    if (!errorParent && parentItem == null) return null

    return { helpItems, parentItem }
  } catch (error) {
    console.log('Error fetching banners', (error as Error).message)
    return null
  }
}


export async function getCartItems(): Promise<ItemMarketplace[] | null> {
  const user = supabase.auth.user()

  try {
    let { data: items, error } = await supabase
      .from<ItemMarketplace>('items')
      .select(
        'id, name, description, price, size, code, url, quantity, image, deleted, has_accessories, custom_order_site, iva, igic_price, igic_percent, shipping_price_to_madrid, marketplace(id, name, logo_url, shipment_price, free_shipment_price, iva_percent, igic_types(name, value, iva_equivalent)), created_at'
      )
      .is('order_id', null)
      .eq('deleted', false)
      .eq('user_email', user?.email)

    if (error) throw Error(error.message)

    if (!error && items == null) return null

    return items
  } catch (error) {
    console.log('Error fetching items', (error as Error).message)
    return null
  }
}

export async function getCartItemsNumber(): Promise<number> {
  const user = supabase.auth.user()

  try {
    let { data, error } = await supabase
      .from<ItemMarketplace>('items')
      .select('id')
      .is('order_id', null)
      .eq('deleted', false)
      .eq('user_email', user?.email)

    if (error) throw Error(error.message)

    return data?.length || 0
  } catch (error) {
    console.log('Error fetching items', (error as Error).message)
    return 0
  }
}

export async function getUserNotifications(): Promise<any[]> {
  const user = supabase.auth.user()

  try {
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('id, email')
      .eq('deleted', false)
      .eq('email', user?.email)
      .single()

    if (userError) throw Error(userError.message)

    const { data: ordersData, error: ordersError } = await supabase
      .from('orders')
      .select('id, estimated_costs_status')
      .in('status_id', [ORDER_STATUS_ID_REQUESTED, ORDER_STATUS_ID_PROCESSED, ORDER_STATUS_ID_SUPPLEMENT, ORDER_STATUS_ID_ADMITTED, ORDER_STATUS_ID_PREPARED, ORDER_STATUS_ID_SENT, ORDER_STATUS_ID_PENALIZATION])
      .eq('user_id', userData.id)

    if (ordersError) throw Error(ordersError.message)

    const ordersIds = ordersData.map((x: any) => x.id)

    const { data: supData, error: supError } = await supabase
      .from<Supplement>('supplements')
      .select('id, status_id, order_id, description, supplement, read_by_user')
      .eq('deleted', false)
      .eq('read_by_user', false)
      .not('email_sent_at', 'is', null)
      .in('order_id', ordersIds)

    if (supError) throw Error(supError.message)

    const notificationList = supData.filter((x: any) => x.read_by_user === false && (x.status_id === ORDER_STATUS_ID_PENDING_PAYMENT || x.supplement < 0)).map((x: any) => {
      return {
        name: `supplement_${x.id}`,
        order_id: x.order_id,
        action: (x.status_id === ORDER_STATUS_ID_PENDING_PAYMENT) ? 'pending_payment' : 'refund',
        severity: (x.status_id === ORDER_STATUS_ID_PENDING_PAYMENT) ? 'normal' : 'low'
      }
    })

    const estimatedCostList = ordersData.filter((x: any) => x.estimated_costs_status === SENT_BUDGE_STATUS_ID).map((x: any) => {
      return {
        name: `advise_${x.id}`,
        order_id: x.order_id,
        action: 'adviseBudget',
        severity: 'low'
      }
    })

    return [...notificationList, ...estimatedCostList] || []
  } catch (error) {
    console.log('Error fetching pending actions', (error as Error).message)
    return []
  }
}

export async function getVariables(names: string[]): Promise<Variable[] | null> {
  try {
    let { data: variables, error } = await supabase
      .from<Variable>('variables')
      .select('name, value, description')
      .in('name', names)

    if (error) throw Error(error.message)

    if (!error && variables == null) return null

    return variables
  } catch (error) {
    console.log('Error fetching variable', (error as Error).message)
    return null
  }
}

export async function getIgicTypes(): Promise<IgicType[] | null> {
  try {
    let { data: igicTypes, error } = await supabase
      .from<IgicType>('igic_types')
      .select('id, name, value')

    if (error) throw Error(error.message)

    if (!error && igicTypes == null) return null

    return igicTypes
  } catch (error) {
    console.log('Error fetching igicTypes', (error as Error).message)
    return null
  }
}

export async function getShipmentMethods(): Promise<ShipmentMethod[] | null> {
  try {
    let { data: methods, error } = await supabase
      .from<ShipmentMethod>('shipment_methods')
      .select('id, name, name, price, shipment_type, shipMode')
      .eq('deleted', false)
      .eq('is_in_home', true)

    if (error) throw Error(error.message)

    if (!error && methods == null) return null

    return methods
  } catch (error) {
    console.log('Error fetching variable', (error as Error).message)
    return null
  }
}

export async function getPaymentMethods(): Promise<PaymentMethod[]> {
  const none: PaymentMethod[] = []
  try {
    let { data: methods, error } = await supabase
      .from<PaymentMethod>('payment_methods')
      .select('id, name, logo_url')
      .eq('deleted', false)

    if (error) throw Error(error.message)

    if (!error && methods == null) return none

    return methods || none
  } catch (error) {
    console.log('Error fetching variable', (error as Error).message)
    return none
  }
}

export async function addItem(item: ItemInput): Promise<ShipmentMethod[] | null> {
  try {
    const user = supabase.auth.user()
    const { data: iva } = await supabase.from('variables').select('value').eq('name', 'IVA_PERCENTAGE').single()

    const igicPercent = (item.has_accessories) ? 3 : 7
		const igicPrice = igicForItem(item.price, item.quantity, iva.value || 21, igicPercent) || 0

    const { data, error } = await supabase.from('items').insert([{ ...item, user_email: user?.email, syniva_price: priceWithoutIva(item.price, iva.value), igic_percent: igicPercent, igic_price: igicPrice }])

    if (error) throw Error(error.message)

    if (!error == null) return null

    return data
  } catch (error) {
    console.log('Error inserting item', (error as Error).message)
    return null
  }
}

export async function updateItem(item: Item): Promise<any[] | null> {
  try {
    const { data, error } = await supabase
      .from('items')
      .update({
        quantity: item.quantity,
        deleted: item.deleted,
      })
      .eq('id', item.id)

    if (error) throw Error(error.message)

    if (!error == null) return null

    return data
  } catch (error) {
    console.log('Error inserting item', (error as Error).message)
    return null
  }
}

export async function emptyCart(): Promise<any[] | null> {
  try {
    const user = supabase.auth.user()

    const { data, error } = await supabase
      .from('items')
      .update({
        deleted: true
      })
      .is('order_id', null)
      .is('deleted', false)
      .eq('user_email', user?.email)

    if (error) throw Error(error.message)

    if (!error == null) return null

    return data
  } catch (error) {
    console.log('Error deleting items for user', (error as Error).message)
    return null
  }
}

export async function getOrderSummary(marketplaceId: number, shipping_method_id: number | null=null, shipMode: boolean = false, promotionalCode: string | undefined= undefined, walletAmountUsed: number): Promise<OrderSummary | null> {
  const user = supabase.auth.user()
  let field: 'marketplace_id' | 'custom_order_site' = 'marketplace_id'
  let value: any = marketplaceId

  const { data: vars } = await supabase.from('variables').select('id, name, value').in('name', [FREE_SHIPMENT_ECONOMY, FREE_ECONOMY_PRICE_MIN])
  const freeShipmentEconomy = vars?.find((x: { name: string, value: string }) => x.name === FREE_SHIPMENT_ECONOMY)?.value || 'false'
  const freeEconomyPriceMin = vars?.find((x: { name: string, value: string }) => x.name === FREE_ECONOMY_PRICE_MIN)?.value || ''

  // Negative marketplaceId means a custom item, so, look for the item ID
  if (marketplaceId < 0) {
    const customItemId = -1 * marketplaceId

    let { data: item } = await supabase
      .from<Item>('items')
      .select('custom_order_site')
      .eq('id', customItemId)
      .single()

    field = 'custom_order_site'
    value = item?.custom_order_site
  }

  try {
    let { data: items, error } = await supabase
      .from<ItemMarketplace>('items')
      .select('id, name, description, price, quantity, deleted, has_accessories, igic_percent, shipping_price_to_madrid, scrapped_data, url, custom_order_site, marketplace(id, shipment_price, free_shipment_price, iva_percent, igic_types(name, value, iva_equivalent))')
      .is('order_id', null)
      .eq('deleted', false)
      .eq('user_email', user?.email)
      .eq(field, value)

    if (error) throw Error(error.message)
    if (!items || items.length === 0) return null

    const vars = await getVariables([VAR_SHIPPING_INSURANCE, VAR_LOW_VALUE_LIMIT, DEFAULT_SHIPPING_METHOD_ID, DEFAULT_SHIPMODE_SHIPPING_METHOD_ID, MAX_KG_DEFAULT_SHIPPING_PRICE, CUSTOM_ORDER_MANAGEMENT_PRICE])
    const igicTypes = await getIgicTypes()
    const shipmentMethods = await getShipmentMethods()

    const isCustom = items.some(item => (item.custom_order_site !== undefined && item.custom_order_site !== null) || (item.scrapped_data !== undefined && item.scrapped_data !== null));
    let igicPercentage = items[0].marketplace?.igic_types.value
    if (isCustom) {
      igicPercentage = (items.some(item => item.has_accessories)) ? igicTypes?.find(x => x.name.toLowerCase() === IGIC_TYPE_REDUCIDO)?.value || 3 : igicTypes?.find(x => x.name.toLowerCase() === IGIC_TYPE_GENERAL)?.value || 7
    }

    let customShopData = undefined
    const superItem = items.filter(item => item.url.startsWith('http')).find(item => item.custom_order_site || item.url) || null
    if (superItem) {
      const nameOfCustomShop = (typeof superItem === 'string')
        ? superItem
        : prepareItemUrl(superItem?.custom_order_site || prepareUrl(superItem?.url)) as any

      const res = await getGroupedMarketplace(nameOfCustomShop)
      if (res?.internal) {
        customShopData = (res.internal?.length > 0) ? res.internal[0] : res.internal
      } else if (res?.related && res?.related?.length > 0) {
        customShopData = res.related[0]
      }
    }
    let ivaPercentage = (superItem?.marketplace?.id && superItem?.marketplace?.id > 0 && typeof superItem?.marketplace?.iva_percent === 'number')
      ? superItem?.marketplace?.iva_percent
      : (items[0]?.marketplace?.id && items[0]?.marketplace?.id > 0 && typeof items[0]?.marketplace?.iva_percent === 'number')
        ? items[0].marketplace?.iva_percent
        : items[0].marketplace?.igic_types.iva_equivalent || 21
    if (customShopData && !shipMode) {
      ivaPercentage = (customShopData.id && customShopData.id > 0 && typeof customShopData.iva_percent === 'number') ? customShopData.iva_percent : items[0].marketplace?.igic_types.iva_equivalent || 21
    }
    /// TODO: set the iva to the item?

    let shippingPriceToMadrid = 0

    items?.forEach((i) => {
      if (i.shipping_price_to_madrid && i.shipping_price_to_madrid !== null && shippingPriceToMadrid < i.shipping_price_to_madrid) {
        shippingPriceToMadrid = i.shipping_price_to_madrid
      }
    })

    const marketplaceFreeShippingPrice = items[0].marketplace.free_shipment_price || 0
    const marketplaceShippingPrice = items[0].marketplace.shipment_price || 0
    const shippingInsurancePercentage = vars?.find((v) => v.name === VAR_SHIPPING_INSURANCE)?.value || '0'
    const lowValueLimit = Number.parseFloat(vars?.find((v) => v.name === VAR_LOW_VALUE_LIMIT)?.value || '0')
    const defaultShipmentMethodId = Number.parseFloat(
      vars?.find((v) => v.name === DEFAULT_SHIPPING_METHOD_ID)?.value || '0'
    )
    const defaultShipModeShipmentMehodId = Number.parseFloat(
      vars?.find((v) => v.name === DEFAULT_SHIPMODE_SHIPPING_METHOD_ID)?.value || '0'
    )

    const subtotalPrice = subtotal(items)

    let marketplaceShippingCost = subtotalPrice < marketplaceFreeShippingPrice ? marketplaceShippingPrice : 0

    const defaultShippingPrice = (shipmentMethods?.find((s) => s.id === (shipping_method_id || defaultShipmentMethodId))?.price || 0)

    let shippingPrice = defaultShippingPrice + marketplaceShippingCost + shippingPriceToMadrid

    const customOrderPrice = Number(vars?.find((v) => v.name === CUSTOM_ORDER_MANAGEMENT_PRICE)?.value || 0)

    if (shipMode) {
      shippingPrice = shipmentMethods?.find((s) => s.id === defaultShipModeShipmentMehodId)?.price || 0

      // ONLY FOR ECONOMY if flag FREE_SHIPMENT_ECONOMY is enabled set to 0 de shipment price
      if (freeShipmentEconomy === 'true') {
        if (subtotalPrice >= Number(freeEconomyPriceMin)) {
          shippingPrice = 0
        }
      }

      shippingPrice = shippingPrice + shippingPriceToMadrid + marketplaceShippingCost
    }

    if (!shipMode) {
      const finalData = total(
        items,
        ivaPercentage,
        igicPercentage,
        lowValueLimit,
        shippingInsurancePercentage,
        shippingPrice,
        false,
        promotionalCode,
        defaultShippingPrice
      )

      // Update igic for each item
      let igicPriceCustom = 0
      if (synivaPrice(items, ivaPercentage) > lowValueLimit) {
        items.forEach((current) => {
          igicPriceCustom += igicForItem(current?.price || 0, current.quantity, ivaPercentage || 21, current.igic_percent, shipMode)
        })
      }

      const data: OrderSummary = {
        subtotal: subtotalPrice,
        syniva_price: synivaPrice(items, ivaPercentage),
        savings: savings(items, ivaPercentage),
        shipping_insurance: shippingInsurance(items, ivaPercentage, shippingInsurancePercentage),
        shipping_method_id: shipping_method_id || defaultShipmentMethodId,
        // igic: igic(items, ivaPercentage, igicPercentage, lowValueLimit),
        igic: igicPriceCustom,
        marketplaceShippingCost: marketplaceShippingCost,
        marketplaceIvaPercent: ivaPercentage,
        total: finalData[0] + ((isCustom) ? customOrderPrice : 0) - Math.abs(walletAmountUsed),
        shipmentMethods: shipmentMethods,
        items: items,
        shippingPriceToMadrid: shippingPriceToMadrid,
        discount: finalData[1] || undefined
      }
      return data
    } else {
      const finalData = total(
        items,
        ivaPercentage,
        igicPercentage,
        lowValueLimit,
        shippingInsurancePercentage,
        shippingPrice,
        true,
        promotionalCode,
        defaultShippingPrice
      )

      // Update igic for each item
      let igicPriceCustom = 0
      if (subtotalPrice > lowValueLimit) {
        items.forEach((current) => {
          igicPriceCustom += igicForItem(current?.price || 0, current.quantity, ivaPercentage || 21, current.igic_percent, shipMode)
        })
      }

      const data: OrderSummary = {
        subtotal: subtotalPrice,
        syniva_price: subtotalPrice,
        savings: 0,
        shipping_insurance: shippingInsurance(items, ivaPercentage, shippingInsurancePercentage, true),
        shipping_method_id: defaultShipModeShipmentMehodId,
        // igic: igic(items, ivaPercentage, igicPercentage, lowValueLimit, true),
        igic: igicPriceCustom,
        marketplaceShippingCost: marketplaceShippingCost,
        marketplaceIvaPercent: ivaPercentage,
        total: finalData[0] + ((isCustom) ? customOrderPrice : 0) - Math.abs(walletAmountUsed),
        shipmentMethods: shipmentMethods,
        items: items,
        shippingPriceToMadrid: shippingPriceToMadrid,
        discount: finalData[1] || undefined
      }
      return data
    }
  } catch (error) {
    console.log('Error inserting item', (error as Error).message)
    return null
  }
}

export async function getSavedCards(): Promise<CreditCard[] | null> {
  const user = supabase.auth.user()
  if (!user || !user.email) return null

  try {
    let { data: cards, error } = await supabase
      .from<CreditCard>('credit_cards')
      .select('id, user_id, token, number, active, user_email')
      .eq('user_email', user.email)

    if (error) throw Error(error.message)
    if (!error && cards == null) return null

    return cards
  } catch (error) {
    console.log('Error fetching variable', (error as Error).message)
    return null
  }
}

export async function getUserAddresses(userEmail?: string): Promise<UserAddresses | null> {
  const user = supabase.auth.user()
  const addressFields =
    'id, street, number, city, postal_code, name, surnames, contact, email, phone_number, island_id, nif, type, additional_info, is_company, full_address, passport_country_code'

  try {
    if (userEmail) {
      let { data, error } = await supabase
        .from<UserAddresses>('users')
        .select(`id, shipping_address(${addressFields}), billing_address(${addressFields})`)
        .eq('deleted', false)
        .eq('email', userEmail)
        .single()

      if (error) throw Error(error.message)

      if (!error && data == null) return null

      return data
    } else {
      let { data, error } = await supabase
        .from<UserAddresses>('users')
        .select(`id, shipping_address(${addressFields}), billing_address(${addressFields})`)
        .eq('deleted', false)
        .eq('email', user?.email)
        .single()

      if (error) throw Error(error.message)

      if (!error && data == null) return null

      return data
    }
  } catch (error) {
    console.log('Error fetching addresses', (error as Error).message)
    return null
  }
}

export async function getUserRole(userEmail?: string): Promise<RoleId | null> {
  try {
    if (userEmail === undefined) {
      return null
    }
    let { data, error } = await supabase
      .from<RoleId>('users')
      .select('id, name, role_id')
      .eq('deleted', false)
      .eq('email', userEmail)
      .single()

    if (error) throw Error(error.message)

    if (!error && data == null) return null

    return data
  } catch (error) {
    console.log('Error fetching role', (error as Error).message)
    return null
  }

}
export async function getIslands(): Promise<Island[]> {
  const none: Island[] = []
  try {
    let { data, error } = await supabase.from<Island>('islands').select('id, name, cp_prefixes').eq('deleted', false)

    if (error) throw Error(error.message)

    if (!error && data == null) return none

    return data || none
  } catch (error) {
    console.log('Error fetching addresses', (error as Error).message)
    return none
  }
}

export async function getUserDefaultCreditCardId(): Promise<number | null> {
  const user = supabase.auth.user()

  if (!user) return null

  try {
    let { data: userData, error } = await supabase
      .from<User>('users')
      .select('default_credit_card_id')
      .eq('email', user.email)

    if (error) throw Error(error.message)
    if (!userData || userData?.length === 0) return null

    return userData[0]?.default_credit_card_id || null
  } catch (error) {
    console.log('Error getting default credit card', (error as Error).message)
    return null
  }
}

// export async function updateUserCreditCardId(cardId: number): Promise<number | null> {
//   const user = supabase.auth.user()

//   if (!user) return null

//   try {
//     let { data: userData, error } = await supabase
//       .from<User>('users')
//       .update({ default_credit_card_id: cardId })
//       .eq('email', user.email)

//     if (error) throw Error(error.message)
//     if (!userData || userData?.length === 0) return null

//     return userData[0]?.default_credit_card_id || null
//   } catch (error) {
//     console.log('Error updating default user credit card', (error as Error).message)
//     return null
//   }
// }

export async function getUserOrders(orderId?: string, userEmail?: string): Promise<OrderData[] | null> {
  const user = supabase.auth.user()

  if (!user || !user.email) return null

  try {
    let { data: userData, error: userError } = await supabase
      .from<User>('users')
      .select('id')
      .eq('email', user.email || userEmail)
      .single()

      if (userError) throw Error(userError.message)
      if (!userData) {
        console.error(`Can not retrieve user ${user?.email || userEmail} orders`)
        return null
      }
      // console.log(`User id retrieved -> ${userData.id}`)

    let query = supabase
      .from<any>('orders')
      .select(
        `id, status_id, status(name), estimated_costs_status, created_at, marketplace_id, subtotal, syniva_price, total_saving, shipping_price, shipping_insurance_price, total, cancelation_date, cancelation_reason, return_date, igic, discount, wallet_amount_used, promotional_code, payment_method_id, custom_order_price, shipping_method_id, prev_status_id,
          marketplaces:marketplace_id(name, url, free_shipment_price, shipment_price),
          items(id, name, description, order_id, marketplaces:marketplace_id(id, name), price, syniva_price, size, code, url, quantity, image, is_custom, shipping_price_to_madrid, user_email, deleted, model, color, disabled, scrapped_data, custom_order_site),
          shipping_address(email, name, surnames, contact, street, number, city, postal_code, phone_number, island_id, nif, type, additional_info, is_company, full_address),
          billing_address(email, name, surnames, contact, street, number, city, postal_code, phone_number, island_id, nif, type, additional_info, is_company, full_address),
          supplements:supplements(id, volumetric, description, supplement, billable_volume, status_id, order_id, reason, read_by_user, parent, email_sent_at),
          refunds:refunds(id, amount),
          estimated_costs:estimated_costs(id, status_id, order_id, created_at, weigth, depth, heigth, length, supplement),
          shipments(tracking_url, tracking_id, shipper_type),
          shipment_methods(id, name)
        `
      )
      .eq('items.deleted', false)
      .eq('supplements.deleted', false)
      .eq('refunds.deleted', false)
      .eq('estimated_costs.deleted', false)
      .gte('estimated_costs.status_id', SENT_BUDGE_STATUS_ID)
      .neq('supplements.supplement', 0)
      .or('description.is.null,description.neq.penalization', { foreignTable: 'supplements' })
      .eq('user_id', userData.id)
      .order('created_at', { ascending: false })

    const { data: auditData, error: auditError } = await supabase.from<any>('audit_orders')
      .select('record->status_id')
      .eq('resource_id', orderId)

    const hadProcessedStatus = auditData?.find((x: any) => x.status_id === 2) || undefined

    if (orderId) {
      query = query.eq('id', orderId)
    }

    let { data: orders, error } = await query

    // console.log(`User ${userData.id} order retrieved successfully`)

    if (error) throw Error(error.message)
    if (!error && orders == null) return null

    if (orders && orders[0] !== null) orders[0].processedStatusRegistered = (hadProcessedStatus !== undefined && hadProcessedStatus !== null)

    return orders
  } catch (error) {
    console.log('Error getting user orders', (error as Error).message)
    return null
  }
}

export async function getBusinessUserOrders(orderId?: string, userEmail?: string): Promise<BusinessOrderData[] | null> {
  const user = supabase.auth.user()

  // console.log(`getting user ${user?.email || userEmail} orders`)

  if (!user || !user.email) return null

  try {
    let { data: userData, error: userError } = await supabase.from<User>('users')
      .select('id')
      .eq('email', user.email || userEmail)
      .single()

      if (userError) throw Error(userError.message)
      if (!userData) {
        console.error(`Can not retrieve user ${user?.email || userEmail} orders`)
        return null
      }

    let { data: userOrders, error: userOrdersError } = await supabase.from<BusinessOrderData>('company_order')
      .select(
        `id, created_at, status_id, reference, package_number, price, vol_price, tracking_id, tracking_url, delivery_note, type, shipper, vol_weight, ticket_name,
        shipping_address_id(id, street, number, city, postal_code, name, surnames, email, phone_number, nif, type, full_address),
        user_id, deleted, updated_at, updated_by, invoice_id, invoice_number`
      )
      .eq('deleted', false)
      .eq('user_id', userData.id)
      .order('created_at', { ascending: false })

    return userOrders
  } catch (error) {
    console.log('Error getting user orders', (error as Error).message)
    return null
  }
}
export async function getBusinessUserOrdersForExcel(userEmail?: string): Promise<any> {
  const user = supabase.auth.user()
  if (!user || !user.email) return null

  const { data: userData, error: userError } = await supabase.from<User>('users')
    .select('id')
    .eq('email', user.email || userEmail)
    .single()

    if (userError) {
      console.error(`Can not retrieve user`)
      return null
    }

  const { data: userOrders, error: userOrdersError } = await supabase.from<any>('company_order')
    .select(
      `id, created_at, status_id, reference, package_number, tracking_id, type, shipper, vol_weight, vol_price, delivery_note,
      shipping_address_id(postal_code, name, surnames, email, phone_number, nif, full_address)`
    )
    .eq('deleted', false)
    .eq('user_id', userData.id)
    .order('created_at', { ascending: false })

  if (userOrdersError) {
    console.error(`Can not retrieve user orders`)
    return null
  }

  return userOrders
}

export async function updateBusinessUserOrders(orderToUpdate: any): Promise<any> {
  try {
    await supabase.from('addresses')
      .update({
        name: orderToUpdate.clientName,
        surnames: orderToUpdate.clientSurnames,
        postal_code: orderToUpdate.clientCP,
        street: orderToUpdate.clientAddress,
        city: orderToUpdate.clientPopulation,
        nif: orderToUpdate.clientDNI,
        phone_number: orderToUpdate.clientPhone,
        email: orderToUpdate.clientEmail
      })
      .eq('id', orderToUpdate.addressId)

    const reference = (String(orderToUpdate.reference).toLowerCase().startsWith('int_')) ? orderToUpdate.reference : `INT_${orderToUpdate.reference}`

    const { data: updateOrder } = await supabase.from('company_order')
      .update({
        reference: reference,
        package_number: orderToUpdate.packageNumber,
        type: orderToUpdate.shippingType,
        invoice_number: orderToUpdate.invoiceNumber,
        updated_at: new Date(Date.now()).toISOString(),
        updated_by: supabase.auth.user()?.email || null
      })
      .eq('id', orderToUpdate.orderId)

    return updateOrder
  } catch (error) {
    console.log('Error updating addresses and order', (error as Error).message)
    return error
  }
}

export async function uploadArchive (archiveData: any): Promise<any> {
  const file = archiveData.file
  const fileName = `${archiveData.orderId}-${archiveData.file.name}`

  try {
    const { error: errorArchive } = await supabase.storage.from('businessinvoices')
      .upload(`${fileName}`, file, {
        cacheControl: '3600',
        upsert: true
      })

    if (errorArchive) {
      console.error('Error upload archive', errorArchive)
      return errorArchive
    }

    const { data, error: errorUpdate } = await supabase.from('company_order')
      .update({
        invoice_id: fileName,
        updated_at: new Date(Date.now()).toISOString(),
        updated_by: supabase.auth.user()?.email || null
      })
      .eq('id', archiveData.orderId)
    if (errorUpdate) {
      console.error('Error upload archive', errorUpdate)
      return errorUpdate
    }
    return data
  } catch (error) {
    console.error('Error upload archive', (error as Error).message)
    return error
  }
}

export async function postBusinessUserOrders(orderToSend: any): Promise<any> {
  const user = supabase.auth.user()

  if (!user || !user.email) return null

  const { data: userInfo, error: userInfoError } = await supabase.from('users').select('id, email').eq('email', user.email).single()

  if (userInfoError) {
    console.error('Error posting data:', userInfoError)
    return
  }

  const { data: addressData, error: addressInsertError } = await supabase.from('addresses')
    .insert({
      name: orderToSend.clientName,
      surnames: orderToSend.clientSurnames,
      postal_code: orderToSend.clientCP,
      street: orderToSend.clientAddress,
      city: orderToSend.clientPopulation,
      nif: orderToSend.clientDNI,
      phone_number: orderToSend.clientPhone,
      email: orderToSend.clientEmail,
      number: '',
      island_id: orderToSend.clientIsland
    })

  if (addressInsertError) {
    console.error('Error posting data:', addressInsertError)
    return
  }

  const reference = (String(orderToSend.reference).toLowerCase().startsWith('int_')) ? orderToSend.reference : `INT_${orderToSend.reference}`

  const { data, error } = await supabase.from('company_order')
    .insert({
      reference: reference,
      package_number: orderToSend.package_number || 1,
      type: orderToSend.shippingType,
      status_id: BUSINESS_STATUS_REQUESTED_ID,
      shipping_address_id: addressData![0].id,
      user_id: userInfo?.id,
      updated_by: userInfo?.email
    })

  if (error) {
    console.error('Error posting data:', error)
    return
  } else {
    return data
  }
}

export async function cancelBusinessOrders(id: number): Promise<definitions['company_order'][] | null> {
  try {
    const { data: updateOrder } = await supabase.from('company_order')
      .update({
        status_id: BUSINESS_STATUS_CANCELLED_ID,
      })
      .eq('id', id)

    return updateOrder
  } catch (error) {
    return null
  }
}

export async function markSupAsRead(supId: number) {
  const user = supabase.auth.user()

  if (!user) return null

  try {
    const { data: updatedData, error } = await supabase.from<Supplement>('supplements').update({
      read_by_user: true
    }).eq('id', supId)

    if (error) throw Error(error.message)

    return updatedData
  } catch (error) {
    console.error(`Error marking as read suplement ${supId} `, (error as Error).message)
    return null
  }
}

export async function updateUserDefaultAddress(address: AddressFormFields, addressType: AddressEnum) {
  const user = supabase.auth.user()

  if (!user) return null

  try {
    const { data: newAddress, error } = await supabase.from<Address>('addresses').insert([address])
    const addressField =
      addressType === AddressEnum.shipping ? 'default_shipping_address_id' : 'default_billing_address_id'

    if (error) throw Error(error.message)

    const data = await fetch(`/api/users/${user.email}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        [addressField]: newAddress[0].id
      })
    })

    if (data.status === 403 || data.status === 401) throw new Error(data.statusText)

    return data

  } catch (error) {
    console.error(`Error updating user ${addressType} address`, (error as Error).message)
    return null
  }
}

export async function removeAccount() {
  const user = supabase.auth.user()

  if (!user) return null

  try {
    const { data, error } = await supabase.rpc('delete_user', { uuid: user.id, email_to_remove: user.email + ` - DELETED ${user.id}` })
    if (error) throw Error(error.message)
    if (data) console.log('removed successfully')

    return true
  } catch (error) {
    console.error(`Error removing user ${user.email}`, (error as Error).message)
    return null
  }
}

export async function getLegal(){

  const { data: legalAdvise, error: errorLegal } = supabase.storage.from('legal').getPublicUrl(LEGAL_ADVISE)
  const { data: privacyPolicy, error:  errorPrivacy } = supabase.storage.from('legal').getPublicUrl(PRIVACY_POLICY)
  const { data: cookiesPolicy, error:  errorCookies } = supabase.storage.from('legal').getPublicUrl(COOKIES_POLICY)
  const { data: buyConditions, error:  errorBuy } = supabase.storage.from('legal').getPublicUrl(BUY_CONDITIONS)
  // const { data: rscTrustSeal, error:  errorRSC } = supabase.storage.from('legal').getPublicUrl(RSC_CERT)

  if (errorLegal || errorPrivacy || errorCookies || errorBuy) {
    return {
      legal: '',
      policy: '',
      cookies: '',
      buy: '',
      rsc: ''
    }
  }

  return {
    legal: legalAdvise?.publicURL,
    policy: privacyPolicy?.publicURL,
    cookies: cookiesPolicy?.publicURL,
    buy: buyConditions?.publicURL,
    // rsc: rscTrustSeal?.publicURL
  }

}

export async function cancelOrder(orderId: number, cancelReason: string | null = null) {
  const currentDate = new Date().toISOString().split('T')[0]

  try {
    const { data, error } = await supabase
      .from('orders')
      .update({
        cancelation_date: currentDate,
        cancelation_reason: (cancelReason) ? cancelReason : null,
        updated_by: null
      })
      .eq('id', orderId)
      .is('cancelation_date', null)

    if (error) throw Error(error.message)

    return data
  } catch (error) {
    console.error(`Error updating order ${orderId}`, (error as Error).message)
  }
}

export async function returnOrder(orderId: number, returnReason: string | null = null) {
  const currentDate = new Date().toISOString().split('T')[0]

  try {
    const { data, error } = await supabase
      .from('orders')
      .update({
        return_date: currentDate,
        return_reason: (returnReason) ? returnReason : null,
        updated_by: null
      })
      .eq('id', orderId)
      .is('return_date', null)

    if (error) throw Error(error.message)

    return data
  } catch (error) {
    console.error(`Error updating order ${orderId}`, (error as Error).message)
  }
}

export async function getAllStatus() {
  const user = supabase.auth.user()

  if (!user) return null

  try {
    let { data, error } = await supabase
      .from<Status>('status')
      .select('id, name')

    if (error) throw Error(error.message)
    if (!data || data?.length === 0) return null

    return data || null
  } catch (error) {
    console.log('Error getting stats', (error as Error).message)
    return null
  }
}

export async function validatePromotionalCode(user: any, code: string, personalData: any, shippingMethodId: number, marketplaceIdPromo: number, shipMode: boolean, promotionalCode: string, device: string, walletAmountUsed: number) {

  const { data: codeData, error: codeError } = await supabase
    .from('codes')
    .select('*')
    .eq('code', code)
    .eq('enabled', true)

  if (codeData?.length === 0 || codeError) {
    return { valid: false, reason: null }
  }

  let codeToUse: any = undefined
  if (codeData && codeData.length > 0) {
    if (codeData[0].scope === 'general' || codeData[0].scope === 'group') {
      codeToUse = codeData[0]
    } else {
      codeToUse = codeData.find(x => x.email === user?.email)
    }
  }

  try {
    if (codeToUse) {
      let personalDataNif = undefined
      if (personalData !== null) {
        const { nif: dataNif } = personalData.shipping_address || personalData.billing_address
        personalDataNif = dataNif
        if (!validateNif(personalDataNif, personalData.shipping_address.is_company)) {
          return { valid: false, reason: { dataError: true } }
        }
      } else {
        return { valid: false, reason: { dataError: true } }
      }

      const results = await Promise.all([
        await supabase.from('users').select('id').eq('email', user?.email).single(),
        await getOrderSummary(marketplaceIdPromo, shippingMethodId, shipMode, promotionalCode, walletAmountUsed),
        await supabase.from('codes_user_relation').select('*').or(`email.ilike.%${user?.email}%, nif.ilike.%${personalDataNif.toLowerCase()}%`),
        await supabase.from('addresses').select('user_id, email, nif').or(`email.ilike.%${user?.email}%, nif.ilike.%${personalDataNif.toLowerCase()}%`)
      ]).then(async (values) => {
        const { data: userData, error: userError } = values[0]
        const summary = values[1]
        const { data: codesRelations, error: codesRelationError } = values[2]
        const { data: addresses, error: addrError } = values[3]

        if (userError || !summary || codesRelationError) {
          return { valid: false, reason: null }
        }

        const { data: orders, error: ordersError } = await supabase
          .from('orders')
          .select('id, promotional_code')
          .eq('user_id', userData.id)
          .gt('status_id', ORDER_STATUS_ID_PENDING_PAYMENT)

        if (ordersError) {
          return { valid: false, reason: null }
        }

        const users: number[] = []
        let usersOrder: any[] = []
        if (addresses && addresses.length > 0) {
          addresses.forEach((a: any) => {
            if (a.user_id && !users.includes(a.user_id)) {
              users.push(a.user_id)
            }
          })

          const { data: uOrders, error: uOrdersError } = await supabase
            .from('orders')
            .select('id, status_id, user_id, promotional_code')
            .in('user_id', users)
            .gt('status_id', 0)

          usersOrder = uOrders || []
        }

        let results = checkCodeConditions(codeToUse, orders, shippingMethodId, personalData, codesRelations, usersOrder, user, summary?.syniva_price || 0, device, marketplaceIdPromo)

        if (results && results.valid) {
          return { valid: results.valid, codeValid: codeToUse.value.value, reason: results.reason || null }
        } else {
          return { valid: false, reason: results?.reason || null }
        }
      }).catch((error) => {
        console.warn(error)
        return { valid: false, reason: null }
      })

      return results
    } else {
      return { valid: false, reason: null }
    }
  } catch (error) {
    console.warn(error)
    return { valid: false, reason: null }
  }
}

export async function updateIvaForItem(itemId: number, iva: number, synivaPrice: number) {
  const { error } = await supabase
    .from('items')
    .update({
      iva: iva,
      syniva_price: synivaPrice
    })
    .eq('id', itemId)

  if (error) {
    return error
  }

  return true
}

export const getGroupedMarketplace = async (siteName: string) => {
  const dataToReturn: any = {
    internal: [],
    related: []
  }

  if (!siteName) return dataToReturn

  const promiseArray = [
    supabase.from('marketplaces')
      .select('id, name, is_custom_shop, extra_info, iva_percent')
      .eq('is_custom_shop', false)
      .eq('deleted', false)
      .ilike('name', `%${siteName.toLowerCase()}%`),
    supabase.from('marketplaces')
      .select('id, name, is_custom_shop, extra_info, iva_percent, internal_shop_id')
      .eq('is_custom_shop', true)
      .eq('deleted', false)
      .ilike('name', `%${siteName.toLowerCase()}%`)
  ]

  const results = await Promise.all(promiseArray)

  results.forEach((r: any, index: number) => {
    if (index === 0) {
      dataToReturn.internal = r.data[0]
    } else {
      dataToReturn.related = r.data
    }
  })

  if (!dataToReturn?.internal || dataToReturn?.internal?.length === 0) {
    const internalShopID = dataToReturn.related.map((r: any) => r.internal_shop_id).filter((x: any) => x !== null)

    if (internalShopID.length > 0) {
      const { data } = await supabase.from('marketplaces')
        .select('id, name, is_custom_shop, extra_info, iva_percent')
        .eq('is_custom_shop', false)
        .eq('deleted', false)
        .in('id', internalShopID)

      if (data) {
        dataToReturn.internal = data
      }
    }
  }

  return dataToReturn
}

export const getSequraOrderById = async (orderId: number) => {
  const { data, error } = await supabase.from<any>('orders')
    .select(
      `id, created_at, subtotal, syniva_price, shipping_price, marketplace_id, shipping_insurance_price, total, igic, discount, payment_method_id, custom_order_price, shipping_method_id, promotional_code,
        items(id, name, description, marketplaces:marketplace_id(id, name), price, syniva_price, size, code, url, quantity, shipping_price_to_madrid, user_email, scrapped_data, custom_order_site),
        shipping_address(email, name, surnames, contact, street, number, city, postal_code, phone_number, island_id, nif, type, additional_info, is_company, full_address),
        billing_address(email, name, surnames, contact, street, number, city, postal_code, phone_number, island_id, nif, type, additional_info, is_company, full_address),
        shipment_methods(id, name)
      `
    )
    .eq('items.deleted', false)
    .eq('id', orderId)
    .single()

  return { data, error }
}

export const registerAccess = async (operation: string, userId: string, email: string | null) => {
  const { data: registry, error: accessError } = await supabase.from('audit_user_access')
    .insert({
      operation: operation,
      resource_id: userId,
      email: email
    }).single()

  if (accessError) {
    console.log(accessError)
  }

  await fetch(`/api/users/${email}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      last_access: registry?.created_at
    })
  })
}

export async function importBusinessFileData(
  bulkDataCompanyOrder: Omit<CompanyOrder, 'id' | 'created_at' | 'updated_at' | 'deleted'>[],
  bulkDataAddresses: Omit<Address, 'id' | 'type' | 'is_company'>[]
) {
  const authUser = supabase.auth.user()

  if (!authUser) throw new Error('Unauthorized')

  const { data: user, error } = await supabase
    .from('users')
    .select('id')
    .eq('email', authUser.email)
    .single()

  if (error) throw new Error('Unauthorized')

  const { data: insertedAddresses, error: addressError } = await supabase
    .from('addresses')
    .insert(bulkDataAddresses)
    .select('id')

  if (addressError) {
    throw new Error('Error inserting addresses: ' + addressError.message)
  }

  const updatedCompanyOrders = bulkDataCompanyOrder.map((companyOrder, index) => {
    const reference = (String(companyOrder.reference).toLowerCase().startsWith('int_')) ? companyOrder.reference : `INT_${companyOrder.reference}`
    return {
      ...companyOrder,
      reference: reference,
      user_id: user.id,
      shipping_address_id: insertedAddresses[index].id
    }
  })

  const { error: companyOrderError } = await supabase
    .from('company_order')
    .insert(updatedCompanyOrders)

  if (companyOrderError) {
    throw new Error('Error inserting company orders: ' + companyOrderError.message)
  }
}

export async function getUserWalletInfo (userId?: string) {

  let user_id: string | undefined = userId || undefined

  if (!user_id) {
    const user = supabase.auth.user()
    user_id = user?.id
  }

  if (!user_id) return null

  const { data } = await supabase.from<definitions['users']>('users')
    .select('id, referral_code, wallet_balance')
    .eq('supabase_user_id', user_id)
    .single()

  if (data) return data
  else return null
}
